import React from 'react';


const IconLinkedin = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 100 100" style={{height: "100%", width: "100%", enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
<path d="M83.3,70.5c2.8-2.2,4.7-5.6,4.7-9.5c0-6.6-5.4-12-12-12c-5.2,0-9.7,3.3-11.3,8c0.2-1,0.3-2,0.3-3c0-8.3-6.7-15-15-15 s-15,6.7-15,15c0,1,0.1,2,0.3,3c-1.6-4.7-6.1-8-11.3-8c-6.6,0-12,5.4-12,12c0,3.8,1.8,7.3,4.7,9.5C9.8,73.3,5,80.1,5,88v2 c0,1.1,0.9,2,2,2h86c1.1,0,2-0.9,2-2v-2C95,80.1,90.2,73.3,83.3,70.5z M76,53c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8s-8-3.6-8-8 C68,56.6,71.6,53,76,53z M68.7,70.5c-0.6,0.2-1.1,0.5-1.7,0.8c-2.3-2.3-5.1-4.1-8.2-5.1c2.5-1.8,4.4-4.4,5.4-7.4 C64.1,59.5,64,60.2,64,61C64,64.8,65.8,68.3,68.7,70.5z M50,43c6.1,0,11,4.9,11,11s-4.9,11-11,11s-11-4.9-11-11S43.9,43,50,43z M36,61c0-0.8-0.1-1.5-0.2-2.3c1,3,2.9,5.6,5.4,7.4c-3.1,1.1-5.9,2.8-8.2,5.1c-0.5-0.3-1.1-0.6-1.7-0.8C34.2,68.3,36,64.8,36,61z M16,61c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.4-3.6,8-8,8S16,65.4,16,61z M27,86v2H9c0-8.3,6.7-15,15-15c2.3,0,4.5,0.5,6.5,1.5 C28.3,77.8,27,81.8,27,86z M69,88H31v-2c0-9.4,7.6-17,17-17h4c9.4,0,17,7.6,17,17V88z M73,88v-2c0-4.2-1.3-8.2-3.5-11.5 c2-0.9,4.1-1.5,6.5-1.5c8.3,0,15,6.7,15,15H73z M35,22H23c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2 C37,21.1,36.1,22,35,22z M15,32h13.6l7.2,5.6c0.4,0.3,0.8,0.4,1.2,0.4c0.3,0,0.6-0.1,0.9-0.2c0.7-0.3,1.1-1,1.1-1.8v-4h4 c1.1,0,2-0.9,2-2V10c0-1.1-0.9-2-2-2H15c-1.1,0-2,0.9-2,2v20C13,31.1,13.9,32,15,32z M17,12h24v16h-4c-1.1,0-2,0.9-2,2v1.9l-4.5-3.5 c-0.4-0.3-0.8-0.4-1.2-0.4H17V12z M71,29c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H73C71.9,31,71,30.1,71,29z M65,39h4v4 c0,0.8,0.4,1.5,1.1,1.8c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.9-0.1,1.2-0.4l7.2-5.6H91c1.1,0,2-0.9,2-2V21c0-1.1-0.9-2-2-2H65 c-1.1,0-2,0.9-2,2v16C63,38.1,63.9,39,65,39z M67,23h22v12H78.7c-0.4,0-0.9,0.1-1.2,0.4L73,38.9V37c0-1.1-0.9-2-2-2h-4V23z"/>
</svg>
  );
};

export default IconLinkedin;