import React from 'react';

const IconGithub = () => {
  return (
    <svg version="1.1"
         id="Layer_1"
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 100 100"
         style={{height: "100%", width: "100%", enableBackground: "new 0 0 100 100"}}
         xmlSpace="preserve"
    >
      <path d="M87,17H13c-4.41,0-8,3.59-8,8v40c0,4.41,3.59,8,8,8h29.56l-1.2,6H37c-1.1,0-2,0.9-2,2s0.9,2,2,2h26c1.1,0,2-0.9,2-2 s-0.9-2-2-2h-4.36l-1.2-6H87c4.41,0,8-3.59,8-8V25C95,20.59,91.41,17,87,17z M13,21h74c2.21,0,4,1.79,4,4v34H9V25 C9,22.79,10.79,21,13,21z M54.56,79h-9.12l1.2-6h6.72L54.56,79z M87,69H13c-2.21,0-4-1.79-4-4v-2h82v2C91,67.21,89.21,69,87,69z M22.65,41.47C22.24,41.1,22,40.56,22,40s0.24-1.1,0.65-1.47l12-11c0.81-0.75,2.08-0.69,2.83,0.12c0.75,0.81,0.69,2.08-0.12,2.83 L26.96,40l10.39,9.53c0.81,0.75,0.87,2.01,0.12,2.83C37.08,52.78,36.54,53,36,53c-0.48,0-0.97-0.17-1.35-0.53L22.65,41.47z M62.65,49.53L73.04,40l-10.39-9.53c-0.81-0.75-0.87-2.01-0.12-2.83c0.75-0.81,2.01-0.87,2.83-0.12l12,11C77.76,38.9,78,39.44,78,40 s-0.24,1.1-0.65,1.47l-12,11C64.97,52.83,64.48,53,64,53c-0.54,0-1.08-0.22-1.48-0.65C61.78,51.54,61.83,50.27,62.65,49.53z M42.24,50.04l12-22c0.53-0.97,1.74-1.33,2.71-0.8c0.97,0.53,1.33,1.74,0.8,2.71l-12,22C45.39,52.62,44.71,53,44,53 c-0.32,0-0.65-0.08-0.96-0.24C42.07,52.23,41.72,51.01,42.24,50.04z"/>
    </svg>
  );
};

export default IconGithub;