import React from 'react';

const IconGoogleDrive = () => {

  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 100 100" style={{height: "100%", width: "100%", enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
<path d="M30,42h18c1.1,0,2-0.9,2-2v-1c0-4.1-2.3-7.7-5.6-9.6c1-1.2,1.6-2.8,1.6-4.4c0-3.9-3.1-7-7-7s-7,3.1-7,7
	c0,1.7,0.6,3.2,1.6,4.4C30.3,31.3,28,34.9,28,39v1C28,41.1,28.9,42,30,42z M36,25c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3
	S36,26.7,36,25z M39,32c3.5,0,6.4,2.6,6.9,6H32.1C32.6,34.6,35.5,32,39,32z M80,8H20c-1.1,0-2,0.9-2,2v80c0,1.1,0.9,2,2,2h60
	c1.1,0,2-0.9,2-2V10C82,8.9,81.1,8,80,8z M78,88H22V12h56V88z M56,20c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2s-0.9,2-2,2H58
	C56.9,22,56,21.1,56,20z M56,30c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2s-0.9,2-2,2H58C56.9,32,56,31.1,56,30z M56,40c0-1.1,0.9-2,2-2
	h12c1.1,0,2,0.9,2,2s-0.9,2-2,2H58C56.9,42,56,41.1,56,40z M28,50c0-1.1,0.9-2,2-2h40c1.1,0,2,0.9,2,2s-0.9,2-2,2H30
	C28.9,52,28,51.1,28,50z M28,60c0-1.1,0.9-2,2-2h40c1.1,0,2,0.9,2,2s-0.9,2-2,2H30C28.9,62,28,61.1,28,60z M28,70c0-1.1,0.9-2,2-2
	h40c1.1,0,2,0.9,2,2s-0.9,2-2,2H30C28.9,72,28,71.1,28,70z M28,80c0-1.1,0.9-2,2-2h40c1.1,0,2,0.9,2,2s-0.9,2-2,2H30
	C28.9,82,28,81.1,28,80z"/>
</svg>
  );
};

export default IconGoogleDrive;